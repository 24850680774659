import { useEffect, useRef, useState, useContext } from "react";
import PulseLoader from 'react-spinners/PulseLoader'
import '@stripe/stripe-js';
import { BreadCrumbContext } from "./context/BreadCrumbContext";
import { useShipmentData } from './context/ShipmentDataContext.js'
import { useFormData } from './context/FormDataContext.js'
import { useAuth } from '../AuthContext.js'
import { useCookies } from 'react-cookie';

import { AlertContext } from "./../body/customerBody/AlertContext"

import { CheckoutContext } from './context/CheckoutContext';

import { useMandate } from './context/PurchaseOrderMandateContext.js';

import { useStripeContext } from './context/StripeContext';

const apiUrl = process.env.REACT_APP_API_URL;

function BreadCrumbButton() {
    const { purchaseOrderMandate, setPurchaseOrderMandate } = useMandate();
    const {user, setUser} = useAuth()
    const { currentStep, goToPreviousStep, goToNextStep, goToEquipment, setOrderNumber } = useContext(BreadCrumbContext);
    const {formData, formData3 } = useFormData()
    const {shippingData} = useShipmentData()
    
    const [cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token'])

    const { showAlertMessage } = useContext(AlertContext);

    const { handleOrderSubmit, isLoading, stripe, elements } = useStripeContext()

    let stripeItems = [];
    if (cookies.cart_cookie !== null && cookies.cart_cookie !== undefined) {
        stripeItems = cookies.cart_cookie.map(item => ({
            id: item.id,
            company_name: item.company_name,
            part_type: item.part_type,
            quantity: item.quantity,
            configurations: item.configurations
        }));
    }
    
    //const handleOrderSubmit = async () => {
    //    setIsLoading(true);
    //    if (!cookies.cart_cookie || cookies.cart_cookie.length < 1) {
    //        showAlertMessage('warning', 'You need to add items to your cart!')
    //        setIsLoading(false);
    //        return 
    //    }
//
    //    const deliveryDetails = {
    //        given_name: `${formData.given_name}`, 
    //        family_name: `${formData.family_name}`,
    //        email: `${user.email}`,
    //        phone: `${formData.contact_number}`,
    //        address: {
    //            city: `${formData.locality}`,
    //            country: `${formData.country}`,
    //            line2: `${formData.suite}`,
    //            line1: `${formData.location}`,
    //            postal_code: `${formData.postal_code}`,
    //            state: `${formData.administrative_area_level_1}`,
    //        },
    //        geolocation: {
    //            latitude: `${formData.latitude}`,
    //            longitude: `${formData.longitude}`
    //        },
    //        shipping_info: {
    //            delivery_method: shippingData.delivery_method,
    //            shipping_type: shippingData.shipping_type
    //        }
    //    }
    //    const billingDetails = {
    //        given_name: `${formData3.given_name}`, 
    //        family_name: `${formData3.family_name}`,
    //        email: `${user.email}`,
    //        phone: `${formData3.contact_number}`,
    //        address: {
    //            city: `${formData3.locality}`,
    //            country: `${formData3.country}`,
    //            line2: `${formData3.suite}`,
    //            line1: `${formData3.location}`,
    //            postal_code: `${formData3.postal_code}`,
    //            state: `${formData3.administrative_area_level_1}`,
    //        },
    //        geolocation: {
    //            latitude: `${formData3.latitude}`,
    //            longitude: `${formData3.longitude}`
    //        }
    //    }
    //    try {
    //        const response = await fetch(`${apiUrl}/api/create-order`, {
    //            method: 'POST',
    //            headers: { 
    //                'Content-Type': 'application/json',
    //                'X-CSRFToken': cookies.csrf_token
    //            },
    //            body: JSON.stringify({ 
    //                delivery_details: deliveryDetails,
    //                billing_details: billingDetails,
    //                user_details: user,
    //                items: stripeItems
    //            })
    //        });
    //        const responseData = await response.json();
    //        if (responseData.success) {
    //            // Payment succeeded without additional actions
    //            setOrderNumber(responseData.orderNumber)
    //            setCookies('cart_cookie', [], { 
    //                path: '/',
    //                expires: new Date(Date.now() + 259200000),
    //                secure: true,
    //                httpOnly: false,
    //                sameSite: 'lax' 
    //            });
    //            goToNextStep()
    //        } else {
    //            // Payment failed
    //            console.error("Order submission failed", responseData.error);
    //            //alert('An error occured processing your order.');
//
    //            // reset the setupintent here
//
//
    //            showAlertMessage('warning', responseData.error)
    //        }
    //    } catch (error) {
    //        console.error('Error:', error);
//
    //        //reset the setup intent here
    //        showAlertMessage('warning', 'An error occured processing your order.')  
    //    } finally {
    //        setIsLoading(false);
    //    }
    //}

    return (
        <div className="step__footer">
            {currentStep === 1 && (
            <button className="step__footer__continue-btn btn" id="continue-btn" name="button" type="submit" onClick={goToNextStep}>
                <span className="btn__content">Continue to Billing</span>
            </button>
            )}
            {currentStep === 2 && (
                <div className="" style={{display: 'flex', flexDirection: "row", gap: "10px"}}>
                    <button className="step__footer__back-btn btn" id="back-btn" name="button" type="submit" onClick={goToPreviousStep}>
                        <span className="btn__content">{"<"}</span>
                    </button>
                    <button className="step__footer__continue-btn btn" id="continue-btn" name="button" type="submit" onClick={goToNextStep}>
                        <span className="btn__content">Continue to Confirmation</span>
                    </button>
                </div>
            )}
            {currentStep === 3 && (
                <div className="" style={{display: 'flex', flexDirection: "row", gap: "10px"}}>
                    <button className="step__footer__back-btn btn" id="back-btn" name="button" type="submit" onClick={goToPreviousStep}>
                        <span className="btn__content">{"<"}</span>
                    </button>
                    <button className="step__footer__continue-btn btn" id="continue-btn" name="button" type="submit" onClick={goToNextStep}>
                        <span className="btn__content">Continue to Payment</span>
                    </button>
                </div>
            )}

            {currentStep === 4 && (
                




                <div className="" style={{display: 'flex', flexDirection: "row", gap: "10px"}}>
                    <button className="step__footer__back-btn btn" id="back-btn" name="button" type="submit" disabled={isLoading} onClick={goToPreviousStep}>
                        <span className="btn__content">{"<"}</span>
                    </button>
                    <button className="step__footer__continue-btn btn" id="continue-btn" name="button" type="submit" disabled={!purchaseOrderMandate || isLoading || !stripe || !elements} onClick={handleOrderSubmit}>
                        <span className="btn__content">
                            {isLoading ? 
                            <span style={{minWidth: '100%'}}><PulseLoader size={5} color={"#FFFFFF"}/> </span>
                                
                                :
                                'Pay Now'}
                        </span>
                    </button>
                </div>
                
            )}
            {currentStep === 5 && (
                <div className="" style={{display: 'flex', flexDirection: "row", gap: "10px"}}>
                    <button className="step__footer__continue-btn btn" id="continue-btn" name="button" type="submit" onClick={goToEquipment}>
                        <span className="btn__content">Return to Equipment</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default BreadCrumbButton;
