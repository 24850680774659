import React, { useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import './css/LandingHeaderComponent.css';
import './css/GoogleLoginButton.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext.js';
import { AlertContext } from './../body/customerBody/AlertContext';
import outlookLogo from './../../assets/Google__G__logo.svg'

const apiUrl = process.env.REACT_APP_API_URL;

function LoginButton() {
    const { setUser } = useAuth();
    const navigate = useNavigate();
    const { showAlertMessage } = useContext(AlertContext);

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.onload = () => initGoogleSignIn();
            document.body.appendChild(script);
        };

        const initGoogleSignIn = () => {
            window.google.accounts.id.initialize({
                client_id: "866596646199-rivlfhgfcbrh3ps9jfa0jin3d1ihleqc.apps.googleusercontent.com",
                ux_mode: "popup",
                callback: handleCredentialResponse,
            });

            createFakeGoogleWrapper(); // Initialize the hidden Google button
        };

        const createFakeGoogleWrapper = () => {
            const googleLoginWrapper = document.createElement('div');
            googleLoginWrapper.style.display = 'none';
            googleLoginWrapper.classList.add('custom-google-button');
            document.body.appendChild(googleLoginWrapper);

            window.google.accounts.id.renderButton(googleLoginWrapper, {
                type: 'icon',
                width: '200', // Arbitrary since it's hidden
            });

            const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');

            // Expose a method to trigger the click on the hidden button
            window.handleGoogleLogin = () => {
                googleLoginWrapperButton.click();
            };
        };

        if (!window.google) {
            loadGoogleScript();
        } else {
            initGoogleSignIn();
        }
    }, []);

    const handleCredentialResponse = async (response) => {
        const userData = jwtDecode(response.credential);
        console.log(userData);
        if (userData.email_verified) {
            try {
                const csrfTokenResponse = await fetch(`${apiUrl}/api/csrf-token`, {
                    credentials: 'include',
                });
                if (!csrfTokenResponse.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }
                const csrfTokenData = await csrfTokenResponse.json();
                const csrfToken = csrfTokenData.csrf_token;
                const GoogleValidationResponse = await fetch(`${apiUrl}/api/google-signin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                    },
                    body: JSON.stringify({ credential: response.credential }),
                    credentials: 'include',
                });

                const testData = await GoogleValidationResponse.json();

                if (testData.success) {
                    const userType = testData.usertype;
                    const validationResponse = await fetch(`${apiUrl}/api/userValidation`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': csrfToken,
                        },
                        body: JSON.stringify({ email: userData.email }),
                        credentials: 'include',
                    });

                    const data = await validationResponse.json();
                    const signUp = data.signup;
                    console.log(data);
                    if (data.success) {
                        if (userType === 'customer') {
                            if (signUp) {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    email: userData.email,
                                    userType: userType,
                                    signUp: true,
                                }));
                            } else {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    userType: userType,
                                    email: data.email,
                                    company: data.company,
                                    given_name: data.given_name,
                                    family_name: data.family_name,
                                    contact_number: data.contact_number,
                                    abn: data.abn,
                                    accounts_email: data.accounts_email,
                                    previous_delivery: data.previous_delivery,
                                    previous_billing: data.previous_billing,
                                }));
                                navigate('/customer');
                            }
                        }
                        if (userType === 'supplier') {
                            if (signUp) {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    email: userData.email,
                                    userType: userType,
                                    signUp: true,
                                }));
                            } else {
                                setUser(prevUser => ({
                                    ...prevUser,
                                    userType: userType,
                                    email: data.email,
                                    company: data.company,
                                    given_name: data.given_name,
                                    family_name: data.family_name,
                                    contact_number: data.contact_number,
                                    abn: data.abn,
                                    accounts_email: data.accounts_email,
                                    previous_delivery: data.previous_delivery,
                                    previous_billing: data.previous_billing,
                                }));
                                navigate('/supplier');
                            }
                        }
                    } else {
                        showAlertMessage('warning', 'You are not authorized to view this page');
                        console.error("You are not authorized to view this page");
                    }
                } else {
                    showAlertMessage('warning', 'You are not authorized to view this page');
                    console.error("You are not authorized to view this page");
                }
            } catch (error) {
                console.error("Failed to authenticate", error);
            }
        } else {
            console.error("Failed to authenticate");
        }
    };

    return (
        
            <button className="outlook-login-button" onClick={() => window.handleGoogleLogin()} style={{marginBottom: '10px'}}>
                <img src={outlookLogo} style={{width: '18px', height: '18px', marginRight: '8px'}}></img>
                <span style={{width: '100%', textAlign: 'left'}}>Continue with Google</span>
            </button>
        
    );
}

export default LoginButton;
